







import { Component, Vue } from "vue-property-decorator";

@Component
export default class LangComp extends Vue {
  lang = "";

  setLang(lang: "tr" | "en"): void {
    this.$store.commit("setLang", lang);

    if (lang == "tr") {
      this.$router.push({ name: "home" });
    } else {
      this.$router.push({ name: "home-en" });
    }

    console.log(this.$store.getters["lang"]);
  }

  mounted() {
    this.lang = this.$store.getters["lang"];
  }
}
