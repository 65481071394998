<template>
  <div class="partners">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" md="3">
          <a href="https://isoc.org.tr/" target="_blank">
            <v-img
              src="@/assets/logo-isoc-tr.png"
              contain
              height="75"
              alt="ISOC-TR İnsternet Derneği"
            />
          </a>
        </v-col>
        <v-col cols="12" md="3">
          <a href="https://medyaokuryazarligi.org/" target="_blank">
            <v-img
              src="@/assets/logo-mod.png"
              contain
              height="75"
              alt="Medya Okuryazarlığı Derneği"
            />
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
