

























































































































import { Component, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import "@/types";
import Lang from "@/components/Lang.vue";

@Component({
  components: {
    Lang
  }
})
export default class TopBar extends Vue {
  get userSignedIn() {
    return this.$store.getters["auth/userSignedIn"];
  }

  get user(): User {
    return this.$store.getters["auth/user"];
  }

  socialLinks = [
    {
      icon: "fab fa-twitter",
      href: "https://twitter.com/diji_vatandas"
    },
    {
      icon: "fab fa-facebook-f",
      href:
        "https://www.facebook.com/Diji-Vatanda%C5%9F-E%C4%9Fitim-Program%C4%B1-102277428588090/"
    },
    {
      icon: "fab fa-linkedin-in",
      href:
        "https://www.linkedin.com/company/di%CC%87ji%CC%87-vatanda%C5%9F-onli%CC%87ne-e%C4%9Fi%CC%87ti%CC%87m-platformu"
    },
    {
      icon: "fab fa-instagram",
      href: "https://www.instagram.com/dijivatandas"
    },
    {
      icon: "fas fa-envelope",
      href: "mailto:iletisim@dijivatandas.org"
    }
  ];

  goTo(path: RawLocation) {
    this.$router.push(path).catch(() => {
      return false;
    });
  }

  signOut() {
    this.$store.dispatch("auth/signOut");
    this.$router
      .replace({
        name: "home"
      })
      .catch(() => {
        return false;
      });
  }

  dialog = false;
}
