




















































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  logo = require("@/assets/logo.png");

  navDrawer = null;

  menuItems = [
    {
      title: "ÇEVRİMİÇİ EĞİTİMLER",
      to: {
        name: "course-catalog"
      }
    },
    {
      title: "HAKKIMIZDA",
      to: {
        name: "about-us"
      }
    },
    {
      title: "PARTNERLERİMİZ",
      to: {
        name: "about-us",
        hash: "#partnerlerimiz"
      }
    },
    {
      title: "SSS",
      to: {
        name: "about-us",
        hash: "#sss"
      }
    }
  ];

  dialog = false;

  get userSignedIn() {
    return this.$store.getters["auth/userSignedIn"];
  }
}
