

















import { Vue, Component } from "vue-property-decorator";

@Component
export default class SocialMediaWidget extends Vue {
  socialLinks = [
    {
      icon: "fab fa-twitter",
      href: "https://twitter.com/diji_vatandas"
    },
    {
      icon: "fab fa-facebook-f",
      href:
        "https://www.facebook.com/Diji-Vatanda%C5%9F-E%C4%9Fitim-Program%C4%B1-102277428588090/"
    },
    {
      icon: "fab fa-linkedin-in",
      href:
        "https://www.linkedin.com/company/di%CC%87ji%CC%87-vatanda%C5%9F-onli%CC%87ne-e%C4%9Fi%CC%87ti%CC%87m-platformu"
    },
    {
      icon: "fab fa-instagram",
      href: "https://www.instagram.com/dijivatandas"
    },
    {
      icon: "fas fa-envelope",
      href: "mailto:iletisim@dijivatandas.org"
    }
  ];
}
